import {StepId} from '../../../domain/models/script';

export type StepWithDimensions = {
	id: StepId;
	left: number;
	top: number;
	width: number;
	height: number;
}

const DEFAULT_STEP_WIDTH = 140 * 1.5;
const DEFAULT_STEP_HEIGHT = 60 * 1.5;

const getStepFreeCoords = (parentStep: StepWithDimensions, existingSteps: StepWithDimensions[]): { left: number; top: number } | null => {
	const stepsGapX = 400;
	const stepsGapY = 250;
	const rotateDegree = 10;
	const initAngle = 270;
	let angle = initAngle
	const freeCoords: { left: number; top: number } = {left: parentStep.left, top: parentStep.top};
	const parentPos = {left: parentStep.left + parentStep.width / 2, top: parentStep.top + parentStep.height / 2};
	let round = 1;
	while (true) {
		const sin = Math.sin(angle * Math.PI / 180);
		const cos = Math.cos(angle * Math.PI / 180);
		angle = angle - rotateDegree;
		if (angle <= -90) {
			angle = initAngle
			round++;
			continue;
		}
		freeCoords.left = Math.round(parentPos.left + sin * (stepsGapX * round + (DEFAULT_STEP_WIDTH / 2)));
		freeCoords.top = Math.round(parentPos.top - cos * (stepsGapY * round + (DEFAULT_STEP_HEIGHT / 2)));
		if (freeCoords.left < 0 || freeCoords.top < 0) {
			continue;
		}
		if (
			!existingSteps.some(step => isStepOverlapping(freeCoords, step))) {
			return freeCoords
		}
		if (round > 100) {
			break
		}
	}

	return null
};

const isStepOverlapping = (step: { left: number; top: number; }, otherStep: StepWithDimensions): boolean => {
	const stepRight = step.left + DEFAULT_STEP_WIDTH;
	const stepBottom = step.top + DEFAULT_STEP_HEIGHT;

	return (
		step.left < otherStep.left + otherStep.width &&
		stepRight > otherStep.left &&
		step.top < otherStep.top + otherStep.height &&
		stepBottom > otherStep.top
	);
};

export const useGetFreeStepPos = () => {
	return {
		getFreeStepPos: (sourceStepId: StepId, stepsWithDimensions: StepWithDimensions[]) => {
			const sourceStep = stepsWithDimensions.find(step => step.id == sourceStepId)
			if (sourceStep) {
				return getStepFreeCoords(sourceStep, stepsWithDimensions)
			} else {
				return null
			}
		}
	}
}
