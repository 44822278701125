import { defineStore } from 'pinia';
import axios from '../plugins/axios';
import { domainSubstitute } from '@/plugins/domain';
export const useUserStore = defineStore('user-store', {
	state: () => ({
		id: null,
		email: null,
		surname: null,
		name: null,
		patronymic: null,
		phone: null,
		company: null,
		amoUser: null,
		bitrixUser: null,
	}),
	getters: {
		getId(): string | null {
			return this.id;
		},

		getEmail(): string | null {
			return this.email;
		},

		getSurname(): string | null {
			return this.surname;
		},

		getName(): string | null {
			return this.name;
		},

		getPatronymic(): string | null {
			return this.patronymic;
		},

		getPhone(): string | null {
			return this.phone;
		},

		getCompany(): string | null {
			return this.company;
		},
		getAutomation(): boolean | null {
			return !!this.amoUser || !!this.bitrixUser;
		},
	},

	actions: {
		disconnectAmoUser() {
			this.amoUser = null;
		},
		disconnectBitrixUser() {
			this.bitrixUser = null;
		},
		async load() {
			try {
				const response = await axios.get(domainSubstitute(import.meta.env.VITE_API_BASE_URL) + '/api/user/current')
				this.id = response.data.id
				this.email = response.data.email
				this.surname = response.data.surname
				this.name = response.data.name
				this.patronymic = response.data.patronymic
				this.phone = response.data.phone
				this.company = response.data.company
				this.amoUser = response.data.amoUser;
				this.bitrixUser = response.data.bitrixUser;
			} catch (error) {
				console.error(error)
			}
		}
	},
});
