import TransparentLayout from '@/layouts/TransparentLayout.vue';

export const issueRoutes = [
	{
		path: 'issue/:id',
		name: 'Issue',
		component: () => import('../pages/issues-page.vue'),
	},
	{
		path: 'issue/passlog',
		name: 'ShowPasslog',
		meta: { layout: TransparentLayout },
		component: () => import('../pages/popup/show-passlog.vue'),
	},
];
