import mitt, { Emitter, Handler } from "mitt";
import { IEventBus } from "./IEventBus";

class EventBus implements IEventBus {
	private emitter: Emitter<any>;

	constructor() {
		this.emitter = mitt();
	}

	on(event: string, callback: (payload: any) => void): void {
		this.emitter.on(event, callback as Handler);
	}

	off(event: string, callback: (payload: any) => void): void {
		this.emitter.off(event, callback as Handler);
	}

	emit(event: string, payload: any): void {
		this.emitter.emit(event, payload);
	}
}

export default EventBus;
