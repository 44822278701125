import notificationBus, { UserNotificationTypes } from "@/notification-bus";
import AiScriptNotification from "@/components/Scripts/AiScriptNotification.vue";

export function handleAiGenerated(data: any) {
	notificationBus.notify({
		type: UserNotificationTypes.SUCCESS,
		title: {
			component: AiScriptNotification,
			props: {
				scriptName: data.script_name,
				scriptID: data.script,
			},
		},
		timeout: 5000,
	});
}
