import { SENDER } from '../../shared/sender';

export type EventKey = 'STARRED_ORDER_CHANGED' |
	'STARRED_CATEGORY_ADDED' |
	'STARRED_CATEGORY_REMOVED' |
	'STARRED_CATEGORY_RENAMED' |
	'STARRED_ERROR' |

	'STEPS_STARRED_CHANGED' |
	'STEPS_IS_GOAL_CHANGED' |
	'STEPS_IS_USER_SORT_CHANGED' |
	'STEPS_ADDED' |
	'STEPS_MOVED' |
	'STEPS_REMOVED' |
	'STEPS_TITLE_RENAMED' |
	'STEPS_TEXT_CHANGED' |
	'STEPS_CONNECTION_ADDED' |
	'STEPS_CONNECTION_UPDATED' |
	'STEPS_CONNECTION_REMOVED' |
	'STEPS_CONNECTION_RENAMED' |
	'STEPS_CONNECTION_SORT_CHANGED' |
	'STEPS_CONNECTION_STATUS_CHANGED' |
	'STEPS_TASK_ADDED' |
	'STEPS_TASK_REMOVED' |

	'ISSUES_ADDED' |
	'ISSUES_REMOVED' |
	'ISSUES_IGNORED' |
	'ISSUES_RESOLVED' |

	'UI_FOCUS_STEP' |
	'UI_FOCUS_AND_CENTER_STEP' |
	'UI_FOCUS_CONNECTION' |
	'UI_FOCUS_STEP_TASKS' |
	'UI_FOCUS_STEP_ISSUES' |
	'UI_FOCUS_STEP_NO_ANSWER' |
	'UI_REDIRECT_SCRIPT'


export type EventName = 'STARRED_ORDER_CHANGED' |
	'STARRED_CATEGORY_ADDED' |
	'STARRED_CATEGORY_REMOVED' |
	'STARRED_CATEGORY_RENAMED' |
	'STARRED_ERROR' |

	'STEPS_STARRED_CHANGED' |
	'STEPS_IS_GOAL_CHANGED' |
	'STEPS_IS_USER_SORT_CHANGED' |
	'STEPS_ADDED' |
	'STEPS_MOVED' |
	'STEPS_REMOVED' |
	'STEPS_TITLE_RENAMED' |
	'STEPS_TEXT_CHANGED' |
	'STEPS_CONNECTION_ADDED' |
	'STEPS_CONNECTION_UPDATED' |
	'STEPS_CONNECTION_REMOVED' |
	'STEPS_CONNECTION_RENAMED' |
	'STEPS_CONNECTION_SORT_CHANGED' |
	'STEPS_CONNECTION_STATUS_CHANGED' |
	'STEPS_TASK_ADDED' |
	'STEPS_TASK_REMOVED' |

	'ISSUES_ADDED' |
	'ISSUES_REMOVED' |
	'ISSUES_IGNORED' |
	'ISSUES_RESOLVED' |

	'UI_FOCUS_STEP' |
	'UI_FOCUS_AND_CENTER_STEP' |
	'UI_FOCUS_CONNECTION' |
	'UI_FOCUS_STEP_TASKS' |
	'UI_FOCUS_STEP_ISSUES' |
	'UI_FOCUS_STEP_NO_ANSWER' |
	'UI_REDIRECT_SCRIPT'


export type Events = Record<EventKey, Event>;
export type Event = {
	name: EventName;
	sender: string;
};

export const events: Events = {
	STARRED_ORDER_CHANGED: {
		name: 'STARRED_ORDER_CHANGED',
		sender: SENDER,
	},
	STARRED_CATEGORY_ADDED: {
		name: 'STARRED_CATEGORY_ADDED',
		sender: SENDER,
	},
	STARRED_CATEGORY_REMOVED: {
		name: 'STARRED_CATEGORY_REMOVED',
		sender: SENDER,
	},
	STARRED_CATEGORY_RENAMED: {
		name: 'STARRED_CATEGORY_RENAMED',
		sender: SENDER,
	},
	STARRED_ERROR: {
		name: 'STARRED_ERROR',
		sender: SENDER,
	},

	STEPS_STARRED_CHANGED: {
		name: "STEPS_STARRED_CHANGED",
		sender: SENDER,
	},
	STEPS_IS_GOAL_CHANGED: {
		name: "STEPS_IS_GOAL_CHANGED",
		sender: SENDER,
	},
	STEPS_IS_USER_SORT_CHANGED: {
		name: "STEPS_IS_USER_SORT_CHANGED",
		sender: SENDER,
	},
	STEPS_ADDED: {
		name: 'STEPS_ADDED',
		sender: SENDER,
	},
	STEPS_MOVED: {
		name: 'STEPS_MOVED',
		sender: SENDER,
	},
	STEPS_REMOVED: {
		name: 'STEPS_REMOVED',
		sender: SENDER,
	},
	STEPS_TITLE_RENAMED: {
		name: 'STEPS_TITLE_RENAMED',
		sender: SENDER,
	},
	STEPS_TEXT_CHANGED: {
		name: 'STEPS_TEXT_CHANGED',
		sender: SENDER,
	},
	STEPS_CONNECTION_ADDED: {
		name: "STEPS_CONNECTION_ADDED",
		sender: SENDER,
	},
	STEPS_CONNECTION_UPDATED: {
		name: "STEPS_CONNECTION_UPDATED",
		sender: SENDER,
	},
	STEPS_CONNECTION_REMOVED: {
		name: "STEPS_CONNECTION_REMOVED",
		sender: SENDER,
	},
	STEPS_CONNECTION_RENAMED: {
		name: "STEPS_CONNECTION_RENAMED",
		sender: SENDER,
	},
	STEPS_CONNECTION_SORT_CHANGED: {
		name: "STEPS_CONNECTION_SORT_CHANGED",
		sender: SENDER,
	},
	STEPS_CONNECTION_STATUS_CHANGED: {
		name: "STEPS_CONNECTION_STATUS_CHANGED",
		sender: SENDER,
	},
	STEPS_TASK_ADDED: {
		name: "STEPS_TASK_ADDED",
		sender: SENDER,
	},
	STEPS_TASK_REMOVED: {
		name: "STEPS_TASK_REMOVED",
		sender: SENDER,
	},

	ISSUES_ADDED: {
		name: "ISSUES_ADDED",
		sender: SENDER,
	},
	ISSUES_REMOVED: {
		name: "ISSUES_REMOVED",
		sender: SENDER,
	},
	ISSUES_IGNORED: {
		name: "ISSUES_IGNORED",
		sender: SENDER,
	},
	ISSUES_RESOLVED: {
		name: "ISSUES_RESOLVED",
		sender: SENDER,
	},


	UI_FOCUS_STEP: {
		name: 'UI_FOCUS_STEP',
		sender: SENDER,
	},
	UI_FOCUS_AND_CENTER_STEP: {
		name: "UI_FOCUS_AND_CENTER_STEP",
		sender: SENDER,
	},
	UI_FOCUS_CONNECTION: {
		name: "UI_FOCUS_CONNECTION",
		sender: SENDER,
	},
	UI_FOCUS_STEP_TASKS: {
		name: "UI_FOCUS_STEP_TASKS",
		sender: SENDER,
	},
	UI_FOCUS_STEP_ISSUES: {
		name: "UI_FOCUS_STEP_ISSUES",
		sender: SENDER,
	},
	UI_FOCUS_STEP_NO_ANSWER: {
		name: "UI_FOCUS_STEP_NO_ANSWER",
		sender: SENDER,
	},
	UI_REDIRECT_SCRIPT: {
		name: "UI_REDIRECT_SCRIPT",
		sender: SENDER,
	},
};
