import { starredRoutes } from "./starred.route";
import { automationRoutes } from "./automation.route";
import { issueRoutes } from "./issues.route";
import { flowchartRoutes	} from './flowchart.route'
import { mainRoutes	} from './main.route'

export const constructorRoutes = [
	{
		path: "/constructor",
		children: [...starredRoutes, ...automationRoutes, ...issueRoutes, ...flowchartRoutes, ...mainRoutes],
	},
];
