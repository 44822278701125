import {createI18n} from 'vue-i18n'
import messages from '@intlify/vite-plugin-vue-i18n/messages'
import dayjs from "dayjs";

const detectionBrowserLocale = () => {
	const language = navigator.languages ? navigator.languages[0] : navigator.language
	const langCode = language.substring(0, 2)

	const validLocales = ['en', 'ru']
	const result = validLocales.includes(langCode) ? langCode : validLocales[0]

	setLocale(result)
	return result
}

const getLocaleFromUrl = () => {
	const regex = /set-locale=([a-z]{2})/i
	const match = regex.exec(window.location.search)
	if (!match?.length)
		return

	return match[1]
}

const setLocale = (locale: string) => {
	document.documentElement.lang = locale
	localStorage.setItem('i18n', locale)
}

const detectCurrentLocale = () => {
	let locale: string | null | undefined = getLocaleFromUrl()
	if (locale) {
		setLocale(locale)
		return locale
	}

	locale = detectLocalStorageLocale()
	if (locale) {
		document.documentElement.lang = locale
		return locale
	}

	locale = detectionBrowserLocale()
	if (locale) {
		setLocale(locale)
	}
	return locale
}

const detectLocalStorageLocale = (): string | null => {
	if (localStorage.getItem('i18n')) {
		document.documentElement.lang = localStorage.getItem('i18n') || ''
		return localStorage.getItem('i18n')
	}

	return null
}

function ruPluralRule(choice: number, choicesLength: number, orgRule: any) {
	if (choice === 0) {
		return 0
	}

	const teen = choice > 10 && choice < 20
	const endsWithOne = choice % 10 === 1
	if (!teen && endsWithOne) {
		return 1
	}
	if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
		return 2
	}

	return choicesLength < 4 ? 2 : 3
}

const locale = detectCurrentLocale() || 'en'
dayjs.locale(locale)
const i18n = createI18n({
	legacy: false,
	locale: locale,
	globalInjection: true,
	fallbackLocale: 'en',
	messages,
	pluralRules: {
		ru: ruPluralRule
	},
})

interface Bcp47Dictionary {
	[key: string]: string
}

const convertLocaleToBcp47 = (locale: string): string => {
	const locales: Bcp47Dictionary = {
		'en': 'en-US',
		'ru': 'ru-RU',
	}

	if (locales[locale])
		return locales[locale]
	else
		console.warn('Locale ' + locale + ' is not found in BCP47 dictionary (i18n)')

	return 'en-US'
}

export {i18n, convertLocaleToBcp47}
