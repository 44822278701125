import { socketService, eventBus } from "@/infrastructure/services";
import { useUserStore } from "@/stores/user";
import { handleAiGenerated } from "../handlers/aiGeneratedHandler";

export async function initWebSocket() {
	const userStore = useUserStore();
	await userStore.load();

	socketService.connect(userStore.getId);
}
