import type { Issue, ScriptId, IssueId, StepId } from '../../../domain/models/script';
import { ScriptModel } from '../../../domain/models/script.model';
import { IEventBusService } from '../../../ports/event-bus-service.port';
import { IScriptStorageService } from '../../../ports/script-storage-service.port';
import { useScriptStorageServiceAdapter } from '../../../services/script-storage.adapter';
import { useEventBusServiceAdapter } from '../../../services/event-bus.adapter';
import { events } from '../events.constant';
import { IApiClientService } from '../../../ports/api-client-service.port';
import { useApiClientServiceAdapter } from '../../../services/api-client.adapter';

const eventBus: IEventBusService = useEventBusServiceAdapter();

export const issuesAdded = async ({ issue, scriptId }: { issue: Issue; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.addIssue(issue);
};

export const issuesRemoved = async ({ issueId, scriptId }: { issueId: IssueId; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.removeIssue(issueId);
};

export const issueIgnored = async ({ issueId, scriptId, stepId }: { scriptId: ScriptId; issueId: number; stepId: StepId }): Promise<void> => {
	const apiClient: IApiClientService = useApiClientServiceAdapter();
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if(!script) return
	const scriptModel = new ScriptModel(script);
	const issue = scriptModel.getIssue(issueId)
	if (issue) {
		if (issue.token) {
			eventBus.emit(events.ISSUES_REMOVED, { issueId, scriptId, stepId });
		} else {
			try {
				await apiClient.ignoreIssue(issueId);
				//await storage.reloadScript(scriptId);
				eventBus.emit(events.ISSUES_REMOVED, { issueId, scriptId, stepId });
			} catch (error) {
				console.error(error);
			}
		}
	}
};

export const issueResolved = async ({ issueId, scriptId, stepId }: { issueId: number; scriptId: ScriptId; stepId: StepId }): Promise<void> => {
	const apiClient: IApiClientService = useApiClientServiceAdapter();
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if(!script) return
	const scriptModel = new ScriptModel(script);
	const issue = scriptModel.getIssue(issueId)
	if (issue) {
		if (issue.token) {
			eventBus.emit(events.ISSUES_REMOVED, { issueId, scriptId, stepId });
		} else {
			try {
				await apiClient.resolveIssue(issueId);
				//await storage.reloadScript(scriptId);
				eventBus.emit(events.ISSUES_REMOVED, { issueId, scriptId, stepId });
			} catch (error) {
				console.error(error);
			}
		}
	}
};

export const registerIssuesEventHandlers = () => {
	eventBus.on(events.ISSUES_IGNORED, issueIgnored, 'issueIgnored handler');
	eventBus.on(events.ISSUES_RESOLVED, issueResolved, 'issueResolved handler');
	eventBus.on(events.ISSUES_ADDED, issuesAdded, 'issueAdded handler');
	eventBus.on(events.ISSUES_REMOVED, issuesRemoved, 'issueRemoved handler');
};
