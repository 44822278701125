import { Logger } from 'tslog';

export const useLogger = () => {
	return new Logger({
		name: 'scriptLogger',
		type: 'pretty',
		prettyLogTemplate: '{{logLevelName}}: ',
		minLevel: 6,
	});
};
