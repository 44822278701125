import TransparentLayout from "@/layouts/TransparentLayout.vue";

export const starredRoutes = [
	{
		path: "starred/:id",
		name: "Starred",
		component: () => import("../pages/starred-page.vue"),
	},
	{
		path: "starred/delete",
		name: "DeleteScriptCategory",
		meta: { layout: TransparentLayout },
		component: () => import("../pages/popup/delete-script-category.vue"),
	},
	{
		path: "starred/edit",
		name: "EditScriptCategory",
		meta: { layout: TransparentLayout },
		component: () => import("../pages/popup/create-edit-script-category.vue"),
	},
];
