import type { UiData } from '../../../shared/ui-data'
import { reactive } from 'vue'

import { ILoggerService } from '../../../ports/logger-service.port'
import { useLoggerServiceAdapter } from '../../../services/logger.adapter'

const loggerPrefix = '📝 UI STATE MUTATED!'
const logger: ILoggerService = useLoggerServiceAdapter()

let store = reactive<UiData>({
	activeStep: null,
	activeConnection: null,
	isCenteredActiveStep: false,
	stepsWithDimensions: [],
});

export const useUiDataStore = () => {
	const getUiData = () => {
		return store || null
	}

	const setUiData = (uiData: UiData) => {
		store = { ...store, ...uiData }
		logger.debug(loggerPrefix)
		logger.trace('METHOD: setUiData, newData: ', uiData)
	}

	const setActiveStep = (stepId: UiData["activeStep"]) => {
		store.activeStep = stepId
		logger.debug(loggerPrefix)
		logger.trace('METHOD: setActiveStep, newData: ', stepId)
	}

	const getActiveStep = () => {
		return store.activeStep
	}

	const setActiveConnection = (index: number) => {
		store.activeConnection = index
		logger.debug(loggerPrefix)
		logger.trace('METHOD: setActiveConnection, newData: ', index)
	}

	const setCenterActiveStep = (isCenteredActiveStep: boolean) => {
		store.isCenteredActiveStep = isCenteredActiveStep
		logger.debug(loggerPrefix)
		logger.trace('METHOD: setCenterActiveStep, newData: ', { isCenteredActiveStep })
	}

	const setStepsWithDimensions = (stepsWithDimensions: UiData["stepsWithDimensions"]) => {
		store.stepsWithDimensions = stepsWithDimensions
		logger.debug(loggerPrefix)
		logger.trace('METHOD: setStepsWithDimensions, newData: ', { stepsWithDimensions })
	}

	const getStepsWithDimensions = () => {
		return store.stepsWithDimensions
	}

	return {
		getUiData,
		setUiData,
		setActiveStep,
		getActiveStep,
		setCenterActiveStep,
		setActiveConnection,
		getStepsWithDimensions,
		setStepsWithDimensions,
	}
}
