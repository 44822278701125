import type { StarredItem, ScriptId, StepId } from '../../../domain/models/script'
import { ScriptModel } from '../../../domain/models/script.model'
import { IScriptStorageService } from '../../../ports/script-storage-service.port'
import { IEventBusService } from '../../../ports/event-bus-service.port'
import { useScriptStorageServiceAdapter } from '../../../services/script-storage.adapter'
import { useEventBusServiceAdapter } from '../../../services/event-bus.adapter'
import { events } from '../events.constant'

const eventBus: IEventBusService = useEventBusServiceAdapter();

export const starredOrderChange = async ({ starred, scriptId }: { starred: StarredItem[]; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.setStarred(starred);
};

export const starredCategoryAdded = async ({ name, scriptId, stepId }: { name: string, scriptId: ScriptId, stepId: StepId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter()
	const scriptResponse = await storage.getScript(scriptId)
	const script = scriptResponse.script
	if (!script) return
	const scriptModel = new ScriptModel(script)
	scriptModel.addStarredCategory(name, stepId)
}

export const starredCategoryRemoved = async ({ index, scriptId }: { index: number; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.removeStarredCategory(index);
};

export const starredCategoryRenamed = async ({ index, newName, scriptId }: { index: number; newName: string; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.renameStarredCategory(index, newName);
};

export const registerStarredEventHandlers = () => {
	eventBus.on(events.STARRED_ORDER_CHANGED, starredOrderChange, 'starredOrderChange handler')
	eventBus.on(events.STARRED_CATEGORY_ADDED, starredCategoryAdded, 'starredCategoryAdded handler')
	eventBus.on(events.STARRED_CATEGORY_REMOVED, starredCategoryRemoved, 'starredCategoryRemoved handler')
	eventBus.on(events.STARRED_CATEGORY_RENAMED, starredCategoryRenamed, 'starredCategoryRenamed handler')
}
