import { defineStore } from 'pinia'
export const useAppStore = defineStore('app-store', {
	state: () => ({
		csrf: '',
	}),
	getters: {
		getCsrf(): string {
			return this.csrf
		},
	},
	actions: {
		setCsrf(value: string) {
			this.csrf = value
		},
	},
})
