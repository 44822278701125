import { App, ref } from "vue";
import { allConditions } from "./conditions/conditions";
import type { FeatureFlagName, FeatureFlags, FeatureToggling } from "./types";
import type { ConditionKeys } from "./conditions/types";

const featureFlags: FeatureFlags = {
	BETA_SCRIPT_AI_GENERATION_BUTTON: { toggled: true, conditions: [] },
};
const flags = ref<FeatureFlags>(featureFlags);

const checkConditions = (conditionsList: Array<ConditionKeys>): boolean => {
	return conditionsList.every((condition) => allConditions[condition]());
};

export default {
	install: (app: App) => {
		const getFeatureValue = (featureName: FeatureFlagName) => {
			const featureConfig = flags.value[featureName];
			return featureConfig.conditions?.length ? checkConditions(featureConfig.conditions) && featureConfig.toggled : featureConfig.toggled;
		};
		const setFeatureValue = (featureName: FeatureFlagName, value: boolean) => {
			if (flags.value[featureName]) {
				flags.value[featureName].toggled = value;
				flags.value = { ...flags.value };
			}
		};

		app.provide<FeatureToggling>("featureToggling", {
			isFeatureToggled: getFeatureValue,
			setFeatureValue: setFeatureValue,
		});
	},
};
