import { createRouter, createWebHistory } from "vue-router";

import TransparentLayout from "@/layouts/TransparentLayout.vue";
import { constructorRoutes } from "@/constructor/infrastructure/script/ui/routes";

const env = import.meta.env.VITE_NODE_ENV;

const knowledgebaseRoutes = [
	{
		path: "/",
		name: "Knowledgebase",
		component: () => import("@/pages/knowledgebase.vue"),
	},
	{
		path: "/profile/edit",
		name: "EditProfile",
		component: () => import("@/pages/profile.vue"),
	},
	{
		path: "/:id",
		name: "KnowledgebaseArticle",
		component: () => import("@/pages/knowledgebase-article.vue"),
	},
];

const aiRoutes = [
	{
		path: "/ai/generate/step/:type",
		name: "AIGenerateStep",
		component: () => import("@/pages/popup/ai-generation-step-text.vue"),
	},
	{
		path: "/ai/generate/objection-list",
		name: "AIGenerateObjectionList",
		component: () => import("@/pages/popup/ai-generation-objection-list.vue"),
	},
];

const versionsRoutes = [
	{
		path: "/scripts/:id/versions",
		name: "PublishedVersions",
		component: () => import("@/pages/published-versions.vue"),
	},
];

const billingRoutes = [
	{
		path: "/billing",
		name: "Billing",
		component: () => import("@/pages/billing-page.vue"),
	},
	{
		path: "/billing/orders",
		name: "BillingOrders",
		component: () => import("@/pages/billing-page.vue"),
	},
];

const scriptsRoutes = [
	{
		path: "/scripts/:id",
		name: "ScriptsFolders",
		component: () => import("@/pages/scripts-page.vue"),
	},
	{
		path: "/scripts",
		name: "ScriptsMain",
		component: () => import("@/pages/scripts-page.vue"),
	},
];

const fieldsRoutes = [
	{
		path: "/fields/:id",
		name: "fields",
		component: () => import("@/pages/fields-page.vue"),
	},
	{
		path: "/constructor-fields/:id",
		name: "constructor-fields",
		meta: { layout: TransparentLayout },
		component: () => import("@/pages/popup/fields-constructor-page.vue"),
	},
];

const notesRoutes = [
	{
		path: "/notes/:id",
		name: "notes",
		component: () => import("@/pages/notes-page.vue"),
	},
];

const postRegisterPopup = [
	{
		path: "/postregister",
		name: "postregister",
		meta: { layout: TransparentLayout },
		component: () => import("@/pages/popup/Postregister.vue"),
	},
];

const scriptAccessPopup = [
	{
		path: "/script-access/:id",
		name: "scriptAccess",
		meta: { layout: TransparentLayout },
		component: () => import("@/pages/popup/script-access.vue"),
	},
];

const dataRoutes = [
	{
		path: "/passing-data/:id",
		name: "PassingData",
		component: () => import("@/pages/passing-data-page.vue"),
	},
];

const analyticsRoutes = [
	{
		path: "/analytics/:id",
		name: "Analytics",
		component: () => import("@/pages/analytics-page.vue"),
	},
];

const developmentRoutes = [
	{
		path: "/validation-form",
		name: "validationForm",
		component: () => import("@/pages/ValidationForm.vue"),
	},
];

const reportsRoutes = [
	{
		path: "/reports/dynamics",
		name: "Reports",
		component: () => import("@/pages/reports-page.vue"),
	},
];

const integrationsRoutes = [
	{
		path: "/integrations/:id",
		name: "Integrations",
		component: () => import("@/pages/integrations-page.vue"),
	},
];

const routes = [
	...knowledgebaseRoutes,
	...versionsRoutes,
	...aiRoutes,
	...billingRoutes,
	...scriptsRoutes,
	...postRegisterPopup,
	...scriptAccessPopup,
	...dataRoutes,
	...analyticsRoutes,
	...fieldsRoutes,
	...notesRoutes,
	...reportsRoutes,
	...constructorRoutes,
	...integrationsRoutes,
	...(env === "development" ? developmentRoutes : []),
];

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		return savedPosition || { top: 0 };
	},
});

export { router };
