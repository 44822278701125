import { App } from "vue"
export const domainSubstitute = (url: string) => {
	const matches = window.location.hostname.match('(\.ru|\.com)$')
	return matches ? url.replace(/(\.ru|\.com)/, matches[0]) : url
}
export default {
	install: (app: App) => {
		app.config.globalProperties.$domainSubstitute = domainSubstitute
	}
}
