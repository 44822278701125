import type { Connection, Step, StepId, ScriptId, StepTitle, ConnectionName, ConnectionStatus, Task, ConnectionId } from '../../../domain/models/script';
import { ScriptModel } from '../../../domain/models/script.model';
import { IScriptStorageService } from '../../../ports/script-storage-service.port';
import { IEventBusService } from '../../../ports/event-bus-service.port';
import { IGetFreeStepPosService } from '../../../ports/get-free-step-pos.port';
import { IUiDataStorageService } from '../../../ports/ui-data-storage-service.port';
import { useUiDataStorageServiceAdapter } from '../../../services/ui-data-storage.adapter';
import { useGetFreeStepPosAdapter } from '../../../services/get-free-step-pos.port';
import { useScriptStorageServiceAdapter } from '../../../services/script-storage.adapter';
import { useEventBusServiceAdapter } from '../../../services/event-bus.adapter';
import { events } from '../events.constant';
import type { Locale } from '@/constructor/shared/locale';
import { DEFAULT_CONNECTION_TEXT } from '../../../shared/default-connection-text.constant';
import { uiFocusAndCenterStep } from '../../use-cases/ui/ui.use-case';

const eventBus: IEventBusService = useEventBusServiceAdapter();
let focusStepTimeout: null | InstanceType<setTimeout>

export const stepsStarredChanged = async ({ stepId, value, scriptId }: { stepId: StepId; value: 'false' | 'true'; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.setActiveStarred(stepId, value);
};

export const stepsIsGoalChanged = async ({ stepId, value, scriptId }: { stepId: StepId; value: boolean; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.setIsGoal(stepId, value);
};

export const stepsIsUserSortChanged = async ({ stepId, value, scriptId }: { stepId: StepId; value: boolean; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.setIsUserSort(stepId, value);
};

export const stepsConnectionSortChanged = async ({ connectionIds, values, scriptId }: { connectionIds: ConnectionId[]; values: number[]; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	connectionIds.forEach((index, idx) => {
		scriptModel.setConnectionSort(index, values[idx]);
	});
};

export const stepsConnectionStatusChanged = async ({ connectionId, status, scriptId }: { connectionId: ConnectionId; status: ConnectionStatus; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.setConnectionStatus(connectionId, status);
};

export const stepsMoved = async ({ stepId, top, left, scriptId }: { stepId: StepId; top: number; left: number; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.moveStep(stepId, top, left);
};

export const stepsConnectionAdded = async ({ connection, scriptId }: { connection: Connection; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.addConnection(connection);
};

export const stepsConnectionRemoved = async ({ connectionId, scriptId }: { connectionId: ConnectionId; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.removeConnection(connectionId);
};

export const stepsConnectionRenamed = async ({ newName, connectionId, scriptId }: { newName: ConnectionName; connectionId: ConnectionId; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.renameConnection(connectionId, newName);
};

export const stepsConnectionUpdated = async ({ connectionId, connection, scriptId, step }: { connectionId: ConnectionId; connection: Connection; scriptId: ScriptId; step: Step }): Promise<void> => {
	const updatedConnection = { ...connection };
	const uiStorage: IUiDataStorageService = useUiDataStorageServiceAdapter();
	const getFreeStepPosService: IGetFreeStepPosService = useGetFreeStepPosAdapter();
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	let newStep = null
	if (step) {
		newStep = {...step}
		const stepsWithDimensions = uiStorage.getStepsWithDimensions();
		if (stepsWithDimensions && stepsWithDimensions.length) {
			const stepCoords = getFreeStepPosService.getFreeStepPos(connection.source || connection.target, uiStorage.getStepsWithDimensions());
			if (stepCoords) {
				const { left, top } = stepCoords;
				newStep.left = left;
				newStep.top = top;
			}
		}
		const stepId = scriptModel.addStep(newStep);
		if (stepsWithDimensions && stepsWithDimensions.length) {
			eventBus.emit(events.STEPS_MOVED, { stepId, top: newStep.top, left: newStep.left, scriptId });
			eventBus.emit(events.UI_FOCUS_STEP, { stepId: stepId });
		}
		updatedConnection.target = updatedConnection.target || stepId;
		updatedConnection.source = updatedConnection.source || stepId;
	}
	scriptModel.updateConnection(connectionId, updatedConnection);
};

export const stepsAdded = async ({ step, scriptId, connection, locale }: { step: Step; scriptId: ScriptId; connection: Connection; locale: Locale }): Promise<StepId | null> => {
	const uiStorage: IUiDataStorageService = useUiDataStorageServiceAdapter();
	const getFreeStepPosService: IGetFreeStepPosService = useGetFreeStepPosAdapter();
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return null;
	const scriptModel = new ScriptModel(script);
	const stepsWithDimensions = uiStorage.getStepsWithDimensions();

	if (stepsWithDimensions && stepsWithDimensions.length) {
		const stepCoords = getFreeStepPosService.getFreeStepPos(connection.source, uiStorage.getStepsWithDimensions());
		if (stepCoords) {
			const { left, top } = stepCoords;
			step.left = left;
			step.top = top;
		}
	}
	scriptModel.addStep(step);
	const defaultConnection: Partial<Connection> = {
		target: step.id,
		condition: DEFAULT_CONNECTION_TEXT[locale],
	};
	if (connection) {
		connection = { ...defaultConnection, ...connection };
		scriptModel.addConnection(connection);
	}
	if (stepsWithDimensions && stepsWithDimensions.length) {
		eventBus.emit(events.STEPS_MOVED, { stepId: step.id, top: step.top, left: step.left, scriptId })
		if (step.other_script !== 'true') {
			if (focusStepTimeout) {
				clearTimeout(focusStepTimeout)
				focusStepTimeout = null
			}
			focusStepTimeout = setTimeout(() => {
				eventBus.emit(events.UI_FOCUS_STEP, { stepId: step.id });
			}, 100)
		}
	}
	return step.id;
};

export const stepsAddedAndFocus = async ({ step, scriptId, connection, locale }: { step: Step; scriptId: ScriptId; connection: Connection; locale: Locale }): Promise<void> => {
	const stepId = await stepsAdded({ step, scriptId, connection, locale });
	if (stepId) {
		uiFocusAndCenterStep(stepId);
	}
};

export const stepsRemoved = async ({ stepId, scriptId }: { stepId: StepId; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const uiStorage: IUiDataStorageService = useUiDataStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.removeStep(stepId);
	const activeStep = uiStorage.getActiveStep()
	if(stepId === activeStep) {
		eventBus.emit(events.UI_FOCUS_STEP, { stepId: null });
	}
};

export const stepsTitleRenamed = async ({ stepId, newName, scriptId }: { stepId: StepId; newName: StepTitle; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.renameStepTitle(stepId, newName);
};

export const stepsTextChanged = async ({ stepId, stepText, scriptId }: { stepId: StepId; stepText: string; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.setStepText(stepId, stepText);
};

export const stepsTaskAdded = async ({ stepId, task, scriptId }: { stepId: StepId; task: Task; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.addStepTask(stepId, task);
};

export const stepsTaskRemoved = async ({ stepId, taskIndex, scriptId }: { stepId: StepId; taskIndex: number; scriptId: ScriptId }): Promise<void> => {
	const storage: IScriptStorageService = useScriptStorageServiceAdapter();
	const scriptResponse = await storage.getScript(scriptId);
	const script = scriptResponse.script;
	if (!script) return;
	const scriptModel = new ScriptModel(script);
	scriptModel.removeStepTask(stepId, taskIndex);
};

export const registerStepsEventHandlers = () => {
	eventBus.on(events.STEPS_STARRED_CHANGED, stepsStarredChanged, 'stepsStarredChanged handler');
	eventBus.on(events.STEPS_IS_GOAL_CHANGED, stepsIsGoalChanged, 'stepsIsGoalChanged handler');
	eventBus.on(events.STEPS_IS_USER_SORT_CHANGED, stepsIsUserSortChanged, 'stepsIsUserSortChanged handler');
	eventBus.on(events.STEPS_ADDED, stepsAdded, 'stepsAdded handler');
	eventBus.on(events.STEPS_MOVED, stepsMoved, 'stepsMoved handler');
	eventBus.on(events.STEPS_CONNECTION_ADDED, stepsConnectionAdded, 'stepsConnectionAdded handler');
	eventBus.on(events.STEPS_CONNECTION_REMOVED, stepsConnectionRemoved, 'stepsConnectionRemoved handler');
	eventBus.on(events.STEPS_CONNECTION_RENAMED, stepsConnectionRenamed, 'stepsConnectionRenamed handler');
	eventBus.on(events.STEPS_CONNECTION_UPDATED, stepsConnectionUpdated, 'stepsConnectionUpdated handler');
	eventBus.on(events.STEPS_CONNECTION_SORT_CHANGED, stepsConnectionSortChanged, 'stepsConnectionSortChanged handler');
	eventBus.on(events.STEPS_CONNECTION_STATUS_CHANGED, stepsConnectionStatusChanged, 'stepsConnectionStatusChanged handler');
	eventBus.on(events.STEPS_REMOVED, stepsRemoved, 'stepsRemoved handler');
	eventBus.on(events.STEPS_TITLE_RENAMED, stepsTitleRenamed, 'stepsTitleRenamed handler');
	eventBus.on(events.STEPS_TEXT_CHANGED, stepsTextChanged, 'stepsTextChanged handler');
	eventBus.on(events.STEPS_TASK_ADDED, stepsTaskAdded, 'stepsTaskAdded handler');
	eventBus.on(events.STEPS_TASK_REMOVED, stepsTaskRemoved, 'stepsTaskRemoved handler');
};
