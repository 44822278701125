import axios from '@/plugins/axios';
import { ref } from 'vue';
import type { ScriptId, PassId } from '../../../domain/models/script';

const loading = ref(false);

export const useApiClient = (ajaxBaseUrl: string, csrf: string) => {
	const cache = { script: { value: null, mutated: false } };
	const getScript = (id: ScriptId) => {
		if (!cache.script.mutated && cache.script.value) {
			return Promise.resolve(cache.script.value);
		}
		loading.value = true;
		const formData = new FormData();
		formData.append('method', 'scripts.load_without_external_scripts');
		formData.append('all_issues', 'true');
		formData.append('id', `${id}`);
		formData.append('csrf_token', csrf);
		return axios({
			method: 'post',
			url: ajaxBaseUrl,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
			.then((response) => {
				cache.script.value = response.data.response;
				cache.script.mutated = false;
				return Promise.resolve(response.data.response);
			})
			.catch((error) => {
				cache.script.mutated = true;
				return Promise.reject(error);
			})
			.finally(() => {
				loading.value = false;
			});
	};

	const getScriptList = (scriptId: ScriptId) => {
		const formData = new FormData();
		formData.append('method', 'scripts.load_script_list');
		formData.append('id', `${scriptId}`);
		formData.append('csrf_token', csrf);
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: ajaxBaseUrl,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then((response) => resolve(response.data.response))
				.catch((error) => reject(error))
				.finally(() => {
					loading.value = false;
				});
		});
	};
	const getPassLog = (passId: PassId) => {
		const formData = new FormData();
		formData.append('method', 'script.get_pass_log');
		formData.append('id', `${passId}`);
		formData.append('csrf_token', csrf);
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: ajaxBaseUrl,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then((response) => resolve(response.data.response))
				.catch((error) => reject(error))
				.finally(() => {
					loading.value = false;
				});
		});
	};
	const getStepNodesByQuery = ({ scriptId, query }: { scriptId: ScriptId; query: string }) => {
		const formData = new FormData();
		formData.append('method', 'script.nodes_find');
		formData.append('script_id', `${scriptId}`);
		formData.append('search', `${query}`);
		formData.append('csrf_token', csrf);
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: ajaxBaseUrl,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then((response) => resolve(response.data.response))
				.catch((error) => reject(error))
				.finally(() => {
					loading.value = false;
				});
		});
	};
	const ignoreIssue = (issueId: number) => {
		cache.script.mutated = true;
		loading.value = true;
		const formData = new FormData();
		formData.append('method', 'script.ignore_issue');
		formData.append('id', `${issueId}`);
		formData.append('csrf_token', csrf);
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: ajaxBaseUrl,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then((response) => resolve(response.data.response))
				.catch((error) => reject(error))
				.finally(() => {
					loading.value = false;
				});
		});
	};
	const resolveIssue = (issueId: number) => {
		cache.script.mutated = true;
		loading.value = true;
		const formData = new FormData();
		formData.append('method', 'script.resolve_issue');
		formData.append('id', `${issueId}`);
		formData.append('csrf_token', csrf);
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: ajaxBaseUrl,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then((response) => resolve(response.data.response))
				.catch((error) => reject(error))
				.finally(() => {
					loading.value = false;
				});
		});
	};

	const getScriptFromMonolith = async () => {
		return new Promise((resolve, reject) => {
			const getScriptFromPostMessage = (event) => {
				if (event.data.event === 'sendScript') {
					const scriptResult = event.data.data;
					if (scriptResult) {
						resolve(scriptResult);
					} else {
						reject(null);
					}
				}
			};
			window.addEventListener('message', getScriptFromPostMessage);
			window.parent.postMessage(
				{
					event: 'giveScript',
					sender: 'frontend:script-storage',
				},
				'*'
			);
		});
	};

	return {
		getScript,
		getScriptList,
		getPassLog,
		ignoreIssue,
		resolveIssue,
		getStepNodesByQuery,
		getScriptFromMonolith,
		cache,
		loading,
	};
};
