import { Event } from "../handlers/events.constant"
import { isOutsideEvent } from "./helper/is-outside-event"
import { ILoggerService } from '../../ports/logger-service.port'
import { useLoggerServiceAdapter } from '../../services/logger.adapter'

const logger: ILoggerService = useLoggerServiceAdapter()
const loggerPrefix = 'EVENT FILTER ⛔: '

export const filterInnerEvents = (payload: any, event: Event, callback: (payload: any) => void, callbackName: string) => {
	if (isOutsideEvent(event)) {
		callback(payload)
	} else {
		logger.debug(loggerPrefix, 'filter affected: filterInnerEvents, event: ', event, 'affected handler: ', callbackName)
		logger.trace('payload: ', payload)
	}
}

export const filterExternalEvents = (payload: any, event: Event, callback: (payload: any) => void, callbackName: string) => {
	if (!isOutsideEvent(event)) {
		callback(payload)
	} else {
		logger.debug(loggerPrefix, 'filter affected: filterExternalEvents, event: ', event, 'affected handler: ', callbackName)
		logger.trace('payload: ', payload)
	}
}
