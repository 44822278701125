import { IWebSocketService } from "./IWebSocketService";
import { IEventBus } from "../event-bus/IEventBus";
import socketEvents from "@/infrastructure/constants/socketEventsDictionary";

class WebSocketService implements IWebSocketService {
	private socket: WebSocket | null = null;
	private userId: string | null = null;
	private eventBus: IEventBus;

	constructor(eventBus: IEventBus) {
		this.eventBus = eventBus;
	}

	connect(userId: string | null) {
		this.userId = userId;
		if (this.socket) return;
		this.socket = new WebSocket(import.meta.env.VITE_WEBSOCKET_URL);

		this.socket.onopen = () => {
			this.onConnect();
		};

		this.socket.onmessage = (event) => {
			const data = JSON.parse(event.data);
			this.eventBus.emit(`ws_${data.type}`, data);
		};

		this.socket.onclose = () => {
			this.onDisconnect();
		};

		this.socket.onerror = (error) => {
			console.error("WebSocket error", error);
			this.eventBus.emit(socketEvents.ERROR, error);
		};
	}

	disconnect() {
		if (this.socket) {
			this.socket.close();
			this.socket = null;
		}
	}

	sendMessage(message: any) {
		if (this.socket && this.socket.readyState === WebSocket.OPEN) {
			this.socket.send(JSON.stringify(message));
		} else {
			console.error("Unable to send message to WebSocket");
		}
	}

	onConnect() {
		this.eventBus.emit(socketEvents.CONNECTED, null);
		this.socket!.send(`{"uid":"${this.userId}"}`);
	}

	onDisconnect() {
		console.warn("WebSocket disconnected");
		this.eventBus.emit(socketEvents.DISCONNECTED, null);
		setTimeout(() => this.connect(this.userId!), 5000);
	}
}

export default WebSocketService;
