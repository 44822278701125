import { IEventBusService } from '../ports/event-bus-service.port'
import { useEventBus } from '../infrastructure/script/event-bus/event-bus'

import { ILoggerService } from '../ports/logger-service.port'
import { useLoggerServiceAdapter } from '../services/logger.adapter'
import { EventName } from '../application/handlers/events.constant'

const logger: ILoggerService = useLoggerServiceAdapter()
const loggerPrefix = '📢 EVENT BUS: '

const logProducer = (producer: string, eventName: EventName, payload: any) => {
	logger.debug(loggerPrefix, 'EVENT NAME 🚩:', eventName, ', PRODUCER 📌: ', producer)
	logger.trace('payload: ', payload)
}

const logConsumer = (consumer: string, eventName: EventName, payload: any) => {
	logger.debug(loggerPrefix, 'EVENT NAME 🚩:', eventName, ', CONSUMER 💪: ', consumer)
	logger.trace('payload: ', payload)
}

export const useEventBusServiceAdapter = (): IEventBusService => {
	return useEventBus(logProducer, logConsumer)
}
