import type { ConnectionId, StepId } from '../../../domain/models/script'
import { IUiDataStorageService } from '../../../ports/ui-data-storage-service.port'
import { IEventBusService } from '../../../ports/event-bus-service.port'
import { useUiDataStorageServiceAdapter } from '../../../services/ui-data-storage.adapter'
import { useEventBusServiceAdapter } from '../../../services/event-bus.adapter'
import { events } from '../events.constant'
import { filterInnerEvents, filterExternalEvents } from '../../event-filters/filterEvents'

const eventBus: IEventBusService = useEventBusServiceAdapter()
const uiDataStorage: IUiDataStorageService = useUiDataStorageServiceAdapter()

export const uiFocusStep = ({ stepId }: { stepId: StepId }): void => {
	uiDataStorage.setActiveStep(stepId)
}

export const uiFocusAndCenterStep = ({ stepId }: { stepId: StepId }): void => {
	uiDataStorage.setCenterActiveStep(true)
	uiDataStorage.setActiveStep(stepId)
	setTimeout(() => {
		uiDataStorage.setCenterActiveStep(false)
	}, 0)
}

export const uiFocusConnection = ({ connectionId }: { connectionId: ConnectionId }): void => {
	uiDataStorage.setActiveConnection(connectionId)
}

export const uiFocusStepIssues = ({ stepId }: { stepId: StepId }): void => {
	uiFocusStep({stepId})
}

export const uiFocusStepTasks = ({ stepId }: { stepId: StepId }): void => {
	uiFocusStep({stepId})
}

export const registerUiEventHandlers = () => {
	eventBus.on(events.UI_FOCUS_STEP, (payload, event) => {
		filterExternalEvents(payload, event, uiFocusStep, 'uiFocusStep handler')
	}, 'uiFocusStep handler')
	eventBus.on(events.UI_FOCUS_STEP, (payload, event) => {
		filterInnerEvents(payload, event, uiFocusAndCenterStep, 'uiFocusAndCenterStepStep handler')
	}, 'uiFocusAndCenterStep handler')
	eventBus.on(events.UI_FOCUS_AND_CENTER_STEP, uiFocusAndCenterStep, 'uiFocusAndCenterStep handler')
	eventBus.on(events.UI_FOCUS_CONNECTION, uiFocusConnection, 'uiFocusConnection handler')
	eventBus.on(events.UI_FOCUS_STEP_ISSUES, uiFocusStepIssues, 'uiFocusStepIssues handler')
	eventBus.on(events.UI_FOCUS_STEP_TASKS, uiFocusStepTasks, 'uiFocusStepTasks handler')
}

