import type {
	Script,
	EditableScript,
	StarredItem,
	StarredItemExtended,
	Step,
	ExtendedStep,
	StepId,
	StepTitle,
	StepText,
	Connection,
	ConnectionId,
	ConnectionExtended,
	ConnectionStatus,
	IssueId,
	Issue,
	Task,
} from './script';
import { protectedStarredCategories } from '../../shared/protected-starred-categories';

export class ScriptModel {
	private script: Script;

	constructor(script: Script) {
		this.script = script;
		if (!this.script.data.connections) this.script.data.connections = [];

		this.script.data.steps.forEach((step) => {
			if (step.left > 32767) step.left = 32767;
			if (step.top > 32767) step.top = 32767;
		})
	}

	/**
	 * populatesConnectionsWithId
	 */
	public populateConnectionsWithId(): void {
		const connections = JSON.parse(JSON.stringify(this.script.data.connections));
		this.script.data.connections = connections.map((conn, index) => ({ ...conn, connection_id: conn.connection_id || conn.source + conn.target + index }));
	}

	/**
	 * returns script
	 * @returns {Script & EditableScript} script object
	 */
	public getScript(): Script & EditableScript {
		return this.script;
	}

	/**
	 * returns starred steps
	 * @returns {StarredItemExtended[]} array of starred steps info
	 */
	public getStarred(): StarredItemExtended[] {
		if (!this.script.data.starred) return [];
		return this.script.data.starred.map((starred) => ({
			n: starred.n,
			s: starred.s
				? starred.s.map((e) => {
						const step = this.findStepById(e);
						return {
							id: e,
							title: step?.title || '',
							is_active: step?.is_starred || false,
							text: step?.text || '',
						};
				  })
				: [],
			protected: protectedStarredCategories.includes(starred.n),
		}));
	}

	/**
	 * returns initial connections
	 * @returns {Connection[]} array of all connections
	 */
	public getInitialConnections(): Connection[] {
		return this.script.data.connections;
	}

	/**
	 * returns initial starred steps
	 * @returns {StarredItem[]} array of starred steps
	 */
	public getInitialStarred(): StarredItem[] {
		return this.script.data.starred;
	}

	/**
	 * returns steps and connections
	 * @returns {steps: Step[]; connections: Connection[]} steps and connections
	 */
	public getSteps(): { steps: Step[]; connections: ConnectionExtended[] } {
		if (!this.script.data.connections) this.script.data.connections = [];
		return {
			steps: this.script.data.steps.map((step) => ({ ...step, issues: this.script.issues?.filter((issue) => step.id === issue.node) || [] })),
			connections: this.script.data.connections.map((connection, index) => ({ ...connection, index: index })),
		};
	}

	/**
	 * returns step
	 * @returns {Step | null} step
	 */
	public getExtendedStep(stepId: StepId): ExtendedStep | null {
		const step = this.script.data.steps.find((step) => step.id === stepId);
		if (!step) return null;
		const starredIndex = this.script.data.starred.findIndex((item) => {
			return item.s && item.s.includes(stepId);
		});
		const starredItem = starredIndex !== -1 ? this.script.data.starred[starredIndex] : null;
		return {
			id: step.id,
			title: step.title,
			text: step.text,
			is_goal: step.is_goal,
			is_active: step?.is_starred || false,
			starred: {
				name: starredItem?.n || '',
				index: starredIndex,
			},
		};
	}

	/**
	 * returns step connections
	 * @param {StepId} id
	 * @returns {incoming: Connection[]; connections: Connection[]} connections
	 */
	public getStepConnections(stepId: StepId): { outcoming: Connection[]; incoming: Connection[] } {
		if (!this.script.data.connections) this.script.data.connections = [];
		return {
			outcoming: this.script.data.connections.filter((connection) => connection.source === stepId),
			incoming: this.script.data.connections.filter((connection) => connection.target === stepId),
		};
	}

	/**
	 * returns step tasks
	 * @param {StepId} stepId
	 * @returns {Task[]} array of step tasks info
	 */
	public getStepTasks(stepId: StepId): Task[] {
		const step = this.findStepById(stepId);
		if (!step) return [];
		return step.tasks || [];
	}

	/**
	 * set new tasks to step
	 * @param {StepId} stepId
	 * @param {Task[]} tasks
	 * @returns {void}
	 */
	public setStepTasks(stepId: StepId, tasks: Task[]): void {
		const step = this.findStepById(stepId);
		if (!step) return;
		step.tasks = tasks;
	}

	/**
	 * add task to step
	 * @param {StepId} stepId
	 * @param {Task} task
	 * @returns {void}
	 */
	public addStepTask(stepId: StepId, task: Task): void {
		const step = this.findStepById(stepId);
		if (!step) return;
		if (!step.tasks) step.tasks = [];
		step.tasks.push(task);
	}

	/**
	 * remove step task
	 * @param {StepId} stepId
	 * @param {number} taskIndex
	 * @returns {void}
	 */
	public removeStepTask(stepId: StepId, taskIndex: number): void {
		const step = this.findStepById(stepId);
		if (!step) return;
		if (!step.tasks || !step.tasks.length) return;
		step.tasks.splice(taskIndex, 1);
	}

	/**
	 * finds step by id
	 * @param {StepId} id
	 * @returns {Step | null} step
	 */
	public findStepById(id: StepId): Step | null {
		return this.script.data.steps.find((step) => step.id === id) || null;
	}

	/**
	 * finds connection by id
	 * @param {ConnectionId} id
	 * @returns {Connection | null} connection
	 */
	public findConnectionById(id: ConnectionId): Connection | null {
		return this.script.data.connections.find((conn) => conn.connection_id === id) || null;
	}

	/**
	 * map to StarredItem from StarredItemExtended
	 * @param {StarredItemExtended[]} items starred extended
	 * @returns {StarredItem[]} starred items
	 */
	static mapToStarredItems(items: StarredItemExtended[]): StarredItem[] {
		return items.map((starred) => ({
			n: starred.n,
			s: starred.s ? starred.s.map((item) => item.id) : [],
		}));
	}

	/**
	 * map to Connection from ConnectionExtended
	 * @param {ConnectionExtended[]} items connections extended
	 * @returns {Connection[]} connection items
	 */
	static mapToConnectionItems(items: ConnectionExtended[]): Connection[] {
		return items.map((connection) => ({
			...connection,
			index: undefined,
		}));
	}

	/**
	 * set StarredItems from StarredItemExtended
	 * @param {StarredItemExtended[]} starred
	 * @returns {void}
	 */
	public setStarred(starred: StarredItem[]): void {
		this.script.data.starred = starred;
	}

	/**
	 * get selected starred item
	 * @param {StepId} id
	 * @param {boolean} value
	 * @returns {void}
	 */
	public getSelectedStarred(id: StepId): StarredItem | null {
		const selectedStarred = this.script.data.starred.find((item) => {
			if (item.s) return item.s.includes(id);
		});
		return selectedStarred ? selectedStarred : null;
	}

	/**
	 * set active starred item
	 * @param {StepId} id
	 * @param {boolean} value
	 * @returns {void}
	 */
	public setActiveStarred(id: StepId, value: 'true' | 'false'): void {
		const step = this.findStepById(id);
		if (!step) return;
		step.is_starred = value;
	}

	/**
	 * set step is_goal
	 * @param {StepId} id
	 * @param {boolean} value
	 * @returns {void}
	 */
	public setIsGoal(id: StepId, value: boolean): void {
		const step = this.findStepById(id);
		if (!step) return;
		step.is_goal = value;
	}

	/**
	 * set step is_user_sort
	 * @param {StepId} id
	 * @param {boolean} value
	 * @returns {void}
	 */
	public setIsUserSort(id: StepId, value: boolean): void {
		const step = this.findStepById(id);
		if (!step) return;
		step.is_user_sort = value;
	}

	/**
	 * set connection sort
	 * @param {ConnectionId} connectionId
	 * @param {number} value
	 * @returns {void}
	 */
	public setConnectionSort(connectionId: ConnectionId, value: number): void {
		const connection = this.findConnectionById(connectionId);
		if (!connection) return;
		connection.sort = value;
	}

	/**
	 * set connection status
	 * @param {connectionId} connectionId
	 * @param {ConnectionStatus} status
	 * @returns {void}
	 */
	public setConnectionStatus(connectionId: ConnectionId, status: ConnectionStatus): void {
		const connection = this.findConnectionById(connectionId);
		if (!connection) return;
		connection.status = status;
	}

	/**
	 * update connection
	 * @param {ConnecitonId} connectionId
	 * @param {Connection} connection
	 * @returns {void}
	 */
	public updateConnection(connectionId: ConnectionId, connection: Connection): void {
		let connectionToUpdate = this.findConnectionById(connectionId);
		if (!connectionToUpdate) return;
		Object.assign(connectionToUpdate, connection);
		connectionToUpdate = { ...connectionToUpdate, ...connection };
	}

	/**
	 * add starred category
	 * @param {string} name
	 * @param {StepId} stepId
	 * @returns {void}
	 */
	public addStarredCategory(name: string, stepId?: StepId): void {
		if (stepId) {
			this.script.data.starred = this.script.data.starred.map((category) => ({ n: category.n, s: category.s ? category.s.filter((id: StepId) => id != stepId) : [] }));
			this.script.data.starred.push({ n: name, s: [stepId] });
		} else {
			this.script.data.starred.push({ n: name, s: [] });
		}
	}

	/**
	 * remove starred category
	 * @param {number} index (array index)
	 * @returns {void}
	 */
	public removeStarredCategory(index: number): void {
		if (protectedStarredCategories.includes(this.script.data.starred[index].n)) {
			throw new Error(`you can't delete protected category`);
		}
		const protectedCategory = this.script.data.starred.find((cat) => protectedStarredCategories.includes(cat.n));
		if (!protectedCategory) return;
		if (this.script.data.starred[index].s) {
			protectedCategory.s = [...protectedCategory.s, ...this.script.data.starred[index].s];
		}
		this.script.data.starred.splice(index, 1);
	}

	/**
	 * rename starred item
	 * @param {number} index
	 * @param {string} newName
	 * @returns {void}
	 */
	public renameStarredCategory(index: number, newName: string): void {
		this.script.data.starred[index].n = newName;
	}

	/**
	 * set steps
	 * @param {Steps[]}	steps
	 * @returns {void}
	 */
	public setSteps(steps: Step[]): void {
		this.script.data.steps = steps;
	}

	/**
	 * returns step connections
	 * @param {StepId} id
	 * @returns {Connection[]} array of certain step connections
	 */
	public getOutcomingConnections(id: StepId): Connection[] {
		const filteredCons = this.script.data.connections.filter((item) => {
			return item.source === id;
		});
		const sortedCons = filteredCons.every((item) => {
			return item.sort;
		});
		if (sortedCons) {
			return filteredCons.sort((a, b) => {
				return b.sort! - a.sort!;
			});
		} else {
			return filteredCons;
		}
	}

	/**
	 * returns step connections
	 * @param {StepId} id
	 * @returns {Connection[]} array of certain step connections
	 */
	public getIncomingConnections(id: StepId): Connection[] {
		const filteredCons = this.script.data.connections.filter((item) => {
			return item.target === id;
		});
		const sortedCons = filteredCons.every((item) => {
			return item.sort;
		});
		if (sortedCons) {
			return filteredCons.sort((a, b) => {
				return b.sort! - a.sort!;
			});
		} else {
			return filteredCons;
		}
	}

	/**
	 * set connections
	 * @param {Connection[]}	connections
	 * @returns {void}
	 */
	public setConnections(connections: Connection[]): void {
		this.script.data.connections = connections;
	}

	/**
	 * add connection
	 * @param {Connection}	connection
	 * @returns {void}
	 */
	public addConnection(connection: Connection): void {
		if (!this.script.data.connections) this.script.data.connections = [];
		this.script.data.connections.push(connection);
	}

	/**
	 * remove connection
	 * @param {ConnectionId}	connectionId
	 * @returns {void}
	 */
	public removeConnection(connectionId: ConnectionId): void {
		if (!this.script.data.connections.length) return;
		this.script.data.connections = this.script.data.connections.filter((conn) => conn.connection_id !== connectionId);
	}

	/**
	 * rename connection
	 * @param {ConnectionId}	connectionId
	 * @param {string}	newName
	 * @returns {void}
	 */
	public renameConnection(connectionId: ConnectionId, newName: string): void {
		if (!this.script.data.connections.length) return;
		const connection = this.findConnectionById(connectionId);
		if (connection) {
			connection.condition = newName;
		}
	}

	/**
	 * move step
	 * @param {StepId}	stepId
	 * @param {number} top
	 * @param {number} left
	 * @returns {void}
	 */
	public moveStep(stepId: StepId, top: number, left: number): void {
		this.script.data.steps.forEach((step) => {
			if (step.id === stepId) {
				step.top = top;
				step.left = left;
			}
		});
	}

	/**
	 * add step
	 * @param {Step}	step
	 * @returns {StepId} step id
	 */
	public addStep(step: Step): StepId {
		this.script.data.steps.push(step);
		if (this.script.data.starred) {
			const protectedCategory = this.script.data.starred.find((cat) => protectedStarredCategories.includes(cat.n));
			if (protectedCategory && !step.other_script) {
				if (protectedCategory.s) {
					protectedCategory.s = [...protectedCategory.s, step.id];
				} else {
					protectedCategory.s = [step.id];
				}
			}
		}
		return step.id;
	}

	/**
	 * remove step
	 * @param {StepId}	stepId
	 * @returns {void}
	 */
	public removeStep(stepId: StepId): void {
		this.script.data.steps = this.script.data.steps.filter((step) => step.id !== stepId);
		this.script.data.starred.forEach((category) => {
			if (!category.s) category.s = [];
			category.s = category.s.filter((step) => step !== stepId);
		});
		if (!this.script.data.connections) this.script.data.connections = [];
		this.script.data.connections = this.script.data.connections.filter((connection) => connection.target !== stepId && connection.source !== stepId);
	}

	/**
	 * rename step name
	 * @param {StepId}	stepId
	 * @param {StepTitle}	newName
	 * @returns {void}
	 */
	public renameStepTitle(stepId: StepId, newName: StepTitle): void {
		this.script.data.steps.forEach((step) => {
			if (step.id === stepId) step.title = newName;
		});
	}

	/**
	 * rename step text
	 * @param {StepId}	stepId
	 * @param {boolean}	newIsGoal
	 * @returns {void}
	 */
	public setStepText(stepId: StepId, stepText: StepTitle): void {
		this.script.data.steps.forEach((step) => {
			if (step.id === stepId) step.text = stepText;
		});
	}

	/**
	 * retrieves the issues associated with the script
	 * @returns {Issue[]} an array of issues
	 */
	public getIssues(id: StepId): Issue[] {
		return this.script.issues.filter((issue) => issue.node === id);
	}

	/**
	 * retrieves the issue by issue id
	 * @returns {Issue} issue
	 */
	public getIssue(id: IssueId): Issue | undefined {
		return this.script.issues.find((issue) => issue.id === id);
	}

	/**
	 * sets the issues associated with the script
	 * @param {Issue[]} issues - an array of issues to be set
	 * @returns {void}
	 */
	public setIssues(issues: Issue[]): void {
		this.script.issues = issues;
	}

	/**
	 * add issue
	 * @param {Issue}	issue
	 * @returns {void}
	 */
	public addIssue(issue: Issue): void {
		if (!this.script.issues) this.script.issues = [];
		this.script.issues.push(issue);
	}

	/**
	 * remove issue
	 * @param {IssueId}	issueId
	 * @returns {void}
	 */
	public removeIssue(issueId: IssueId): void {
		if (!this.script.issues.length) return;
		this.script.issues = this.script.issues.filter((issue) => issue.id !== issueId);
	}
	/**
	 * Returns the text of a step with all field references replaced
	 * with the name of the field.
	 *
	 * @param {StepId} stepId - The ID of the step to get the text of.
	 * @returns {string} - The text of the step with all field references
	 */
	public getStepTextWithFields(stepId: StepId): string {
		const step = this.findStepById(stepId);
		if (step && step.text) {
			return step.text.replace(/<hs class="js_field js_non_editable" data-id="(\d+)"><\/hs>/g, (match, id) => {
				const fieldId = parseInt(id, 10);
				const field = this.script.fields[fieldId];
				return field ? `<hs class="js_field js_non_editable" data-id="${id}">${field.name}</hs>` : match;
			});
		}
		return '';
	}

	/**
	 * Prepares step text for saving by reverting field names to field IDs.
	 *
	 * @param {StepText} stepText - The text of the step to prepare for saving.
	 * @returns {StepText} - The step text with field names replaced by field IDs.
	 */
	public prepareStepTextForSaving(stepText: StepText): StepText {
		return stepText.replace(/<hs class="js_field js_non_editable" data-id="(\d+)">[^<]*<\/hs>/g, (match, id) => {
			return `<hs class="js_field js_non_editable" data-id="${id}"></hs>`;
		});
	}
}
