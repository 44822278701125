import mitt, {Emitter} from 'mitt'
import { Component } from 'vue'

export enum UserNotificationTypes {
    SUCCESS,
    ERROR,
}

export class UserNotification {
    type: UserNotificationTypes
    title: string | Component
    timeout: number = 1000

    constructor(type: UserNotificationTypes, title: string | Component, timeout?: number) {
        this.type = type
        this.title = title
        if (timeout) {
            this.timeout = timeout
        }
    }
}

export class SuccessUserNotification extends UserNotification {
    constructor(title: string | Component, timeout?: number) {
        super (UserNotificationTypes.SUCCESS, title, timeout)
    }
}

export class ErrorUserNotification extends UserNotification {
    constructor(title: string | Component, timeout?: number) {
        if (!timeout) {
            timeout = 3000
        }
        super (UserNotificationTypes.ERROR, title, timeout)
    }
}

class notificationBus {
    emitter: Emitter<any>

    constructor() {
        this.emitter = mitt()
        this.emitter.on('*', (type, e) => console.debug('🦌 Notification:', type, e ? e : '' ))
    }

    notify(notification: UserNotification) {
        this.emitter.emit('notify', notification)
    }

    on(handler: any) {
        this.emitter.on('notify', handler)
    }
}

const bus = new notificationBus()

export default bus
