<template>
	<Component :is="$route.meta.layout || DefaultLayout">
		<RouterView />
	</Component>
</template>

<script lang="ts">
import DefaultLayout from "./layouts/DefaultLayout.vue";
import { initNotifications, initWebSocket, registerSocketEventHandlers } from "@/infrastructure/modules";
import { useAppStore } from "@/stores/app";
import { registerStarredEventHandlers } from "@/constructor/application/handlers/starred/starred.handler";
import { registerStepsEventHandlers } from "@/constructor/application/handlers/steps/steps.handler";
import { registerUiEventHandlers } from "@/constructor/application/handlers/ui/ui.handler";
import { registerPostMessageHandler } from "@/constructor/application/handlers/post-message.handler";
import { registerIssuesEventHandlers } from "@/constructor/application/handlers/issues/issues.handler";

export default {
	watch: {
		$route(to, from) {
			window.parent.postMessage(
				{
					event: "routeChanged",
					message: to.fullPath,
					sender: "frontend",
				},
				"*"
			);
		},
	},

	setup() {
		const appStore = useAppStore();
		return { appStore, DefaultLayout };
	},

	created() {
		initNotifications();
	},

	mounted() {
		window.addEventListener("message", (event) => {
			if (event.data.event === "csrf" && event.data.sender === "monolith") this.appStore.setCsrf(event.data.message);
		});

		window.parent.postMessage(
			{
				event: "mounted",
				sender: "frontend",
			},
			"*"
		);
		initWebSocket();
		registerSocketEventHandlers();
		registerStarredEventHandlers();
		registerStepsEventHandlers();
		registerUiEventHandlers();
		registerPostMessageHandler();
		registerIssuesEventHandlers();
	},
};
</script>
