<script setup lang="ts">
const props = defineProps({
	scriptName: {
		type: String,
		default: "",
	},
	scriptID: {
		type: Number,
		default: null,
	},
});

const goToScript = (id: string) => {
	window.parent.postMessage(
		{
			event: "goToScript",
			message: "/scripts/" + id,
			sender: "frontend",
		},
		"*"
	);
};
</script>

<template>
	<div class="notification">
		<p class="notification__line">
			<strong>{{ $t("ai-script-status.created") }}</strong>
		</p>
		<p class="notification__line">{{ props.scriptName }}</p>
		<br />
		<p class="notification__line">
			<span @click="goToScript(String(props.scriptID))" class="notification__link">{{ $t("ai-script-status.open-script") }}</span>
		</p>
	</div>
</template>

<style scoped lang="sass">
$white-color: #fff

.notification
	&__line
		margin: 0
	&__link
		color: $white-color
		text-decoration: underline
		cursor: pointer
</style>
