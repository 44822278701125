export const parseJwt = (token: string) => {
	var base64Url = token.split(".")[1];
	if (!base64Url || base64Url.length === 0) return "";
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map(function (c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);

	return JSON.parse(jsonPayload);
};

export const getJwtTokenFromCookie = () => {
	let matches = document.cookie.match(new RegExp("(?:^|; )" + "jwt_token".replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)"));
	return matches ? decodeURIComponent(matches[1]) : undefined;
};
