import { IEventBusService } from '../../ports/event-bus-service.port'
import { useEventBusServiceAdapter } from '../../services/event-bus.adapter'
import { ILoggerService } from '../../ports/logger-service.port'
import { useLoggerServiceAdapter } from '../../services/logger.adapter'
import { ISocketService } from '../../ports/socket-service.port'
import { useSocketServiceAdapter } from '../../services/socket-service.adapter'
import { events } from './events.constant'
import { filterInnerEvents, filterExternalEvents } from '../event-filters/filterEvents'
import { SENDER } from '@/constructor/shared/sender'

const eventBus: IEventBusService = useEventBusServiceAdapter()
const logger: ILoggerService = useLoggerServiceAdapter()
const socket: ISocketService = useSocketServiceAdapter()

const eventNames = Object.values(events).map(event => event.name)

const loggerPrefix = '📬 POST MESSAGE SERVICE: '

export const registerPostMessageHandler = () => {

	socket.onMessage((event) => {
		if (!event.event || !event.event.name) return
		if (event.event.sender === SENDER) return
		if (!eventNames.includes(event.event?.name)) return
		filterInnerEvents(event.payload, event.event, (payload) => {
			logger.debug(loggerPrefix, 'ICOMING EVENT: ', event.event)
			logger.trace('payload: ', payload)
			eventBus.emit(event.event, event.payload, 'postMessageListener')
		}, 'postMessageListener')
	})

	Object.values(events).forEach((event) => {
		eventBus.on(event, (payload, event) => {
			filterExternalEvents(payload, event, (payload) => {
				logger.debug(loggerPrefix, 'OUTCOMING EVENT: ', event)
				logger.trace('payload: ', payload)
				socket.send({
					event,
					payload,
					sender: 'frontend',
				})
			}, 'postMessageHandler')
		}, 'postMessageHandler')
	})
}

