// Using context_action convention for events naming

const socketEvents = {
	AI_GENERATED: "ws_ai-generated",
	CONNECTED: "ws_connected",
	DISCONNECTED: "ws_disconnected",
	ERROR: "ws_error",
};

export default socketEvents;
