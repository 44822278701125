import { createGtm } from '@gtm-support/vue-gtm';
import {router} from "@/plugins/vue-router";

const gtm = createGtm({
	id: import.meta.env.VITE_GTM_ID || 'GTM-UNDEFINED',
	defer: false,
	enabled: true,
	debug: false,
	loadScript: true,
	vueRouter: router,
	trackOnNextTick: false,
})

export { gtm }
