import {EventData, Handler, ISocketService} from '../../../ports/socket-service.port'
let handler: Handler | null = null

window.addEventListener('message', (event: {data: EventData}) => {
	if (handler) {
		handler(event.data)
	}
})

const onMessage = (fn: Handler) => {
	handler = fn
}

const send = (data: EventData) => {
	window.parent.postMessage(data, "*");
}

export const useSocket = () => {
	return {
		onMessage,
		send
	} as ISocketService
}
