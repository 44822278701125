import { createApp } from 'vue'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/ru'
import Editor from './components/Shared/Editor.vue';
import App from './App.vue'

/**
 * Plugins
 */
import { i18n } from './plugins/i18n'
import { pinia } from './plugins/pinia'
import { router } from './plugins/vue-router'
import { toast } from './plugins/toastification'
import { gtm } from './plugins/gtm'
import domain from './plugins/domain'
import featureToggling from './plugins/feature-toggling/feature-toggling'

/**
 * Styles
 */
import 'normalize.css'
import '@smpartners/hyperscript-common-ui/style.css'
import './assets/styles/main.scss'
import './components/SchemaEditor/_styles.sass'
import 'uno.css'
import * as Sentry from '@sentry/vue'

/**
 * init app
 */
const app = createApp(App)
app.config.globalProperties.$dayjs = dayjs
dayjs.extend(LocalizedFormat)

app
	.use(domain)
	.use(featureToggling)
	.use(i18n)
	.use(pinia)
	.use(router)
	.use(toast, { shareAppContext: true })
	.use(gtm)
	.component('Editor', Editor)


const sentryClient = Sentry.init({
	app,
	dsn: 'https://238c5957be9e61e4ded03f7b22b86416@o4508043180965888.ingest.de.sentry.io/4508043183784016',
	release: "hs-frontend@" + import.meta.env.VITE_RELEASE_VERSION,
	integrations: [
		Sentry.browserTracingIntegration({ router, instrumentPageLoad: false }),
	],
	tracesSampleRate: 1.0,
	environment: import.meta.env.VITE_SENTRY_ENVIRONMENT || 'development',
})

const urlParams = new URL(window.location.href);
const sentryTrace = urlParams.searchParams.get('sentryTrace');
const sentryBaggage = urlParams.searchParams.get('sentryBaggage');
if (sentryTrace) {
	Sentry.startBrowserTracingPageLoadSpan(
		sentryClient,
		{
			name: window.location.pathname.replace(/\/[0-9]+$/, '/*'),
		},
		{ sentryTrace, sentryBaggage },
	);
}

app.mount('#app')
