import { useAppStore } from "@/stores/app";
import { useApiClient } from "../infrastructure/script/api-client/api-client";
import { IApiClientService } from "../ports/api-client-service.port";
import { domainSubstitute } from "@/plugins/domain";

export const useApiClientServiceAdapter = (): IApiClientService => {
	const ajaxBaseUrl = domainSubstitute(import.meta.env.VITE_AJAX_BASE_URL);
	const appStore = useAppStore();
	const csrf = appStore.getCsrf;
	const apiCLient = useApiClient(ajaxBaseUrl, csrf);
	return apiCLient;
};
