import { EventName, Event } from "../../../application/handlers/events.constant";
import { IEventBusService } from "../../../ports/event-bus-service.port";

const events: Record<string, Callback[]> = {}
const consumerNames = new Map<Callback, string>()
type Callback = (payload: any, event: Event) => void

export const useEventBus = (
	logProducer: (producerName: string, eventName: EventName, payload: any) => void,
	logConsumer: (consumerName: string, eventName: EventName, payload: any) => void
) => {

	const eventBus: IEventBusService = {
		on(event: Event, callback: Callback, consumerName: string) {
			if (consumerName) {
				consumerNames.set(callback, consumerName)
			}
			if (!events[event.name]) {
				events[event.name] = []
			}
			events[event.name].push(callback)
		},
		off(event: Event, callback: Callback) {
			if (!events[event.name]) return
			const index = events[event.name].indexOf(callback)
			if (index >= 0) {
				events[event.name].slice(index, 1)
			}
			if (consumerNames.has(callback)) {
				consumerNames.delete(callback)
			}
		},
		emit(event: Event, payload: any, producerName: string) {
			if (producerName) {
				logProducer(producerName, event.name, payload)
			}
			const channel = events[event.name]
			if (!channel || !channel.length) return
			channel.forEach(callback => {
				const consumerName = consumerNames.get(callback)
				if (consumerName) {
					logConsumer(consumerName, event.name, payload)
				}
				callback(payload, event)
			})
		}
	}
	return eventBus
}
