import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import {domainSubstitute} from './domain'
import { i18n, convertLocaleToBcp47 } from "./i18n";

// clean-up
const frm = document.querySelector('iframe[name="sso"]')
if (frm && frm.parentNode)
	frm.parentNode.removeChild(frm)

// inject auth iframe
const iframe = document.createElement("iframe")
iframe.style.display = "none"
iframe.style.width = "1px"
iframe.style.height = "1px"
iframe.name = "sso"
iframe.src = domainSubstitute(import.meta.env.VITE_SSO_URL)
document.body.appendChild(iframe)

const channel = new MessageChannel();
const port1 = channel.port1;
const port2 = channel.port2;
const onLoad = () => {
	window.sso.postMessage("init", "*", [port2]);
}
iframe.addEventListener("load", onLoad);

const sendChannelMessage = (message: any, target: MessagePort) => {
	return new Promise((resolve, reject) => {
		if (!target || !target.postMessage) {
			reject(new Error('Invalid target'))
		}
		const ch = new MessageChannel()

		target.postMessage(message, [ch.port2])

		target.onmessage = ({data}) => {
			if (data.status === 'error') {
				reject(new Error(data.message))
				return
			}
			if (data.event !== 'sso-loaded')
				resolve(data)
		}
	})
}

const refreshAuthLogic = (failedRequest: any) =>
	sendChannelMessage({event: 'refreshToken'}, port1)
	.then(function () {
		return Promise.resolve();
	})
	.catch((e) => {
		return Promise.reject(e);
	})

const axiosInstance = axios.create({
	withCredentials: true,
	headers: {
		Accept: 'application/json',
	},
})

export const axiosWithoutCredentials = axios.create({
	headers: {
		Accept: 'application/json',
	},
})

createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic)
axiosInstance.interceptors.request.use(
	config => {
		config.headers!['Content-Language'] = convertLocaleToBcp47(i18n.global.locale.value);
		return config;
	},
	error => {
		return Promise.reject(error);
	}
)

export default axiosInstance
