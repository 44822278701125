import notificationBus, { UserNotification, UserNotificationTypes } from "@/notification-bus";
import { useToast } from "vue-toastification";

const toast = useToast();

export function initNotifications() {
	notificationBus.on((e: UserNotification) => {
		switch (e.type) {
			case UserNotificationTypes.SUCCESS:
				toast.success(e.title, { timeout: e.timeout });
				break;
			case UserNotificationTypes.ERROR:
				toast.error(e.title, { timeout: e.timeout });
				break;
		}
	});
}
