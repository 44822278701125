import { reactive } from 'vue';
import type { Script, ScriptId } from '../../../domain/models/script';
import { ScriptModel } from '../../../domain/models/script.model';

import { ILoggerService } from '../../../ports/logger-service.port';
import { useLoggerServiceAdapter } from '../../../services/logger.adapter';
import { useApiClientServiceAdapter } from '../../../services/api-client.adapter';

const loggerPrefix = '📝 SCRIPT STATE MUTATED!';

const logger: ILoggerService = useLoggerServiceAdapter();

let store = reactive<{ script: Script | null }>({
	script: null,
});

export const useScriptStore = () => {

	async function getFakeScript(fakeScript: string) {
		const response = await fetch('/' + fakeScript);
		if (!response.ok) {
			throw new Error(`Response status: ${response.status}`);
		}
		const scriptFixture = await response.json();
		const scriptModel = new ScriptModel(JSON.parse(JSON.stringify(scriptFixture)));
		scriptModel.populateConnectionsWithId()
		store.script = scriptModel.getScript();
		return store;
	}

	const getScript = async (id: ScriptId) => {
		const apiClient = useApiClientServiceAdapter();
		if (store.script) return store;
		const urlParams = new URL(window.location.href);
		const fakeScript = urlParams.searchParams.get('fakeScript');
		if (fakeScript) {
			return await getFakeScript(fakeScript);
		}
		const response = await apiClient.getScriptFromMonolith(id);
		if (response) {
			const scriptModel = new ScriptModel(response);
			scriptModel.populateConnectionsWithId()
			store.script = scriptModel.getScript();
		} else {
			store.script = null;
		}
		return store;
	};

	const reloadScript = async (id: ScriptId) => {
		const apiClient = useApiClientServiceAdapter();
		const urlParams = new URL(window.location.href);
		const fakeScript = urlParams.searchParams.get('fakeScript');
		if (fakeScript) {
			return await getFakeScript(fakeScript);
		}
		try {
			const response = await apiClient.getScript(id);
			if (response.data.response) {
				const scriptModel = new ScriptModel(response.data.response);
				store.script = scriptModel.getScript();
			} else {
				store.script = null;
			}
			logger.debug(loggerPrefix);
			logger.trace('new data: ', response);
			return store;
		} catch (error) {
			console.error(error);
			return null;
		}
	};

	const setScript = async (script: Script): Promise<void> => {
		store.script = script;
		logger.debug(loggerPrefix);
		logger.trace('new data: ', script);
	};

	return {
		getScript,
		reloadScript,
		setScript,
	};
};
